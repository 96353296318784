import React from "react";
import SEO from "../seo/seo";
import {graphql, useStaticQuery} from "gatsby";

const Header = () => {
    const data = useStaticQuery(graphql`
       query {
            site{
                siteMetadata {
                    title
                }
            }
        }
   `)

    return (
        <SEO title={data.site.siteMetadata.title} />
    )
}

export default Header