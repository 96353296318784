/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faLinkedinIn,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";

const Footer = () => {
    const data = useStaticQuery(graphql`
       query {
            site{
                siteMetadata {
                    author,
                    phone,
                    email
                }
            }
        }
   `)

    return (
        <footer className="footer text-muted">

            <div className="container text-center">
                <div className="topLine ms-5 me-5 mb-5"></div>
                <div className="row">
                    <div className="col-sm-4">
                        <p><span className="fw-bold">Sídlo společnosti:</span><br />
                            Media Luna s.r.o.<br />
                            Rybná 716/24<br />
                            110 00 Praha 1
                        </p>
                    </div>
                    <div className="col-sm-4">
                        <p><span className="fw-bold">Provozovna:</span><br />
                            Media Luna s.r.o.<br />
                            Smilova 307<br />
                            530 02 Pardubice
                        </p>
                    </div>

                    <div className="col-sm-4 text-center">
                        <a className={"me-3"} href="https://www.facebook.com/medialuna.cz" target="_blank">
                            <FontAwesomeIcon className={"text-muted"} icon={faFacebook} />
                        </a>
                        <a className={"me-3"} href="https://www.instagram.com/medialuna.cz/" target="_blank">
                            <FontAwesomeIcon className={"text-muted"} icon={faInstagram}/>
                        </a>
                        <br />
                        <br />
                        <a href="mailto:terapie.medialuna@gmail.com">terapie.medialuna@gmail.com</a>

                    </div>



                    
                </div>

            </div>
            <div className="bg-light-border">
                <div className="container py-4">

                    <p className="float-end mb-1">
                        <a href="#">Nahoru</a>
                    </p>
                    <p className="small">{data.site.siteMetadata.author} © {new Date().getFullYear()}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer
