import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Logo() {
    return (
        <div className={"text-center mt-3"}>
            <div className={"brand-name"}>
                <Link to={'/'} href="#">
                    <StaticImage imgClassName={"logo"} src="./logomedialuna.png" alt="Media Luna" />
                </Link>
            </div>
        </div>


    )
        ;
}
