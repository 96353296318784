import React from "react";
import {Link} from "gatsby";
import Logo from "../Logo/logo";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import { AnchorLink } from "gatsby-plugin-anchor-links";

class TopMenu extends React.Component {
    componentDidMount() {
        var scrollpos = window.scrollY;
        var header = document.querySelector(".navbar-jazz");

        function add_class_on_scroll() {
            header.classList.remove("navbar-dark");
            header.classList.add("navbar-light");
        }

        function remove_class_on_scroll() {
            header.classList.remove("navbar-light");
            header.classList.add("navbar-dark");
        }

        window.addEventListener('scroll', function(){
            //Here you forgot to update the value
            scrollpos = window.scrollY;

            if(scrollpos > 300 ){

                add_class_on_scroll();
            }
            else {
                remove_class_on_scroll();
            }
          //  console.log(scrollpos);
        });
    }

    render() {
        return (

                <nav className="navbar navbar-expand-md navbar-dark fixed-top shadow-sm navbar-jazz">
                <div className="container-xl container-fluid">
                    <Link to={"/"} className="navbar-brand text-primary">
                        <Logo/>

                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        MENU
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                            <li className="nav-item">

                                <AnchorLink to="#novinky" className="nav-link">Novinky</AnchorLink>
                            </li>
                            <li className="nav-item">

                                <AnchorLink to="#restMenu" className="nav-link">Jedálny lístok</AnchorLink>
                            </li>


                            <li className="nav-item">

                                <AnchorLink to="#fotogalerie" className="nav-link">Fotogaléria</AnchorLink>
                            </li>

                            <li className="nav-item me-lg-4">
                                <AnchorLink to="#kontakt" className="nav-link">Kontakt</AnchorLink>

                            </li>

                            <li className="nav-item nav-contact">
                                <a href='https://www.facebook.com/jazztikot'
                                   target={"_blank"} className="nav-link ">
                                    <FontAwesomeIcon icon={faFacebook}/>
                                </a>
                            </li>
                            {/*<li className="nav-item nav-contact">
                                <a href='https://www.instagram.com/petrbalvin/'
                                   target={"_blank"} className="nav-link ">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </li>*/}
                            <li className="nav-item nav-contact">
                                <a href='tel:00421915178895' className="nav-link">
                                    <FontAwesomeIcon icon={faPhone}/>
                                </a>
                            </li>



                        </ul>

                    </div>
                </div>
            </nav>

        )
    }
}

export default TopMenu

