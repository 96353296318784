import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import TopMenu from "./Topmenu";
import LeftMenu from "./LeftMenu";

export default function Layout(props) {
    return (
        <>
            <Header/>
            <main>
                <div className="container-fluid ">
                    <LeftMenu/>
                    <div className="row">
                        <div className="col-12 col-md-9 ps-0 pe-0 ms-auto">
                                {props.children}
                            <Footer/>
                        </div>
                    </div>
                </div>
            </main>


            <div className={`${process.env.CSS_DEV}`}></div>
        </>
    )
}