import React from "react";
import { Link } from "gatsby";
import Logo from "../Logo/logo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";



class LeftMenu extends React.Component {

    render() {
        return (
            <div className="leftMenu h-100">

                <Logo />


                <nav className="navbar navbar-expand-md flex-column navbar-light pt-md-5">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"></a>
                        <button className="navbar-toggler mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMediaLuna" aria-controls="navbarMediaLuna" aria-expanded="false" aria-label="Toggle navigation">
                           Menu <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarMediaLuna">
                            <ul className="navbar-nav me-auto mx-auto mb-2 mb-lg-0 flex-column align-items-center">
                                <li className="nav-item">
                                    <Link to={'/'} className="nav-link" href="#">Úvod</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/omne'} className="nav-link" href="#">O mně</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/individualnilekce'} className="nav-link" href="#">Individuální lekce</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/terapiebemer'} className="nav-link" href="#">Terapie BEMER</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={'/kontakt'} className="nav-link" href="#">Kontakt</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>

            </div>

        )
    }
}

export default LeftMenu

